























































.vertual-row__cell {
  &:nth-child(1) {
    width: 52px;
    text-align: center;
    flex: 0 0 auto;
  }
  &:nth-child(2) {
    width: 184px;
    flex: 0 0 auto;
  }
  &:nth-child(3) {
    flex: 1 1 auto;
  }
}
