









































































































































































.vertual {
  &-header {
    &__cell {
      text-align: center;
      &:nth-child(1) {
        width: 52px;
      }
      &:nth-child(2) {
        width: 184px;
      }
      &:nth-child(3) {
      }
    }
  }
}
