














































.vertual-wrap::v-deep {
  .vertual {
    &-header {
      display: flex;
      width: 100%;
      border-top: 1px solid #222222;
      border-bottom: 1px solid #e1e1e1;
      &__cell {
        flex: 0 0 auto;
        padding: 8px 4px;
        background-color: #fbfbfb;
        color: #222;
        font-weight: normal;
        font-size: 12px;
        &:last-child {
          flex: 1 1 auto;
        }
      }
    }
    &-row {
      display: flex;
      width: 100%;
      //border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      &__cell {
        flex: 0 0 auto;
        padding: 4px;
        text-align: left;
        font-size: 12px;
        line-height: 1.5;
        word-break: break-all;
        &:last-child {
          flex: 1 1 auto;
        }
      }
      &--select {
        box-shadow: inset 0 0 0 1px #5551ce;
      }
    }
  }
}
