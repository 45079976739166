



































































































































































































































































































































































































































































































































































































































































































.td_ldap .btn_tag_ldap {
  margin: 2px 0 0 12px;
}
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
